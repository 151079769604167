import styled from 'styled-components';

export const StyleContactSocial = styled.div<{ portBg?: string }>`
  .contact-items {
    border: 0;
    border-radius: 0;
    box-shadow:
      1px 0 0 0 #e9edf1,
      0 1px 0 0 #e9edf1,
      1px 1px 0 0 #e9edf1,
      1px 0 0 0 #e9edf1 inset,
      0 1px 0 0 #e9edf1 inset;
    transition: all 0.2s;
    height: 100%;
    .content {
      p {
        word-break: break-all;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .small {
      @media (max-width: 767px) {
        font-size: 11px;
      }
    }
  }
`;
