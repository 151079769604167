import React from 'react';
import { StyleServiceBgImageSection } from './styles';
import { Button } from '@/components/elements';

interface ServiceBgImageSectionProps {
  // Props definition
  onClick?: () => void;
  btnTxt?: string;
  title?: string | React.ReactNode;
}

const ServiceBgImageSection = (props: ServiceBgImageSectionProps) => {
  const { onClick, btnTxt = 'ลงทะเบียนขอคำปรึกษา', title } = props;
  return (
    <StyleServiceBgImageSection
      style={{
        backgroundImage: `url(/images/service/10-security.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <div className="flex w-full items-center justify-center py-20">
        <div className="max-w-screen-3xl text-center">
          {title && (
            <h3 className="mb-10 space-y-4 font-bold text-white lg:text-4xl">{title}</h3>
          )}
          {btnTxt && (
            <Button htmlType="button" className="btn-warning" onClick={onClick}>
              {btnTxt}
            </Button>
          )}
        </div>
      </div>
    </StyleServiceBgImageSection>
  );
};

export default ServiceBgImageSection;
