import React from 'react';
import { PartnerBox } from '@/components/modules';
import { StylePartnerSection } from './styles';

const PartnerSection = () => {
  return (
    <StylePartnerSection>
      <div className="focus-el mx-auto grid w-full max-w-screen-3xl grid-cols-1 md:grid-cols-4">
        <PartnerBox
          title="นวัตกรรมและเทคโนโลยี"
          imageMd="/images/partner/mitta.webp"
          imageLg="/images/partner/mitta.webp"
          width={150}
          height={150}
        />
        <PartnerBox
          title="ทีมงานที่มีความเชี่ยวชาญ"
          imageMd="/images/partner/rgb72.webp"
          imageLg="/images/partner/rgb72.webp"
          width={150}
          height={150}
        />
        <PartnerBox
          title="ความพึงพอใจของลูกค้า"
          imageMd="/images/partner/neumerlin.webp"
          imageLg="/images/partner/neumerlin.webp"
          width={150}
          height={150}
        />
        <PartnerBox
          title="ความพึงพอใจของลูกค้า"
          imageMd="/images/partner/cyberrex.webp"
          imageLg="/images/partner/cyberrex.webp"
          width={150}
          height={150}
        />
      </div>
    </StylePartnerSection>
  );
};

export default PartnerSection;
