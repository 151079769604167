import styled from 'styled-components';

export const StyleElementTitle = styled.div`
  .title-inner {
    position: relative;
    margin-bottom: 1.5rem;
    > .prologue {
      position: absolute;
      top: 0;
      font-size: 40px;
      font-weight: 600;
      line-height: 0.8;
      text-transform: uppercase;
      transform: translateY(-60%);
      width: 100%;
      @media only screen and (min-width: 768px) {
        font-size: 70px;
      }
      @media only screen and (min-width: 992px) {
        font-size: 140px;
      }
      &.prologue-center {
        left: 50%;
        transform: translate(-50%, -75%);
      }
    }
    > .subtitle {
      font-size: 28px;
      font-weight: 400;
      line-height: 1.6em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: #262626;
      margin: 0 0 4px;
      position: relative;
      z-index: 1;
      @media only screen and (min-width: 992px) {
        font-size: 38px;
      }
      &-top {
        font-size: 28px;
        font-weight: 400;
        line-height: 1.6em;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: #262626;
        margin: -22px 0 0 4px;
        position: relative;
        z-index: 1;
        @media only screen and (min-width: 992px) {
          font-size: 38px;
        }
      }
    }
    > .title {
      font-size: 38px;
      font-weight: 600;
      line-height: 1.25;
      text-transform: uppercase;
      letter-spacing: 0.15px;
      color: #262626;
      margin: 0;
      position: relative;
      z-index: 1;
      @media only screen and (min-width: 768px) {
        font-size: 38px;
      }
      @media only screen and (min-width: 992px) {
        font-size: 3.2rem;
      }
      @media only screen and (min-width: 1280px) {
        font-size: 3.5rem;
      }
      @media only screen and (min-width: 1440px) {
        font-size: 60px;
      }
    }
  }
  &.align-prologue-center {
    .title-inner {
      > .prologue {
        top: 22px;
      }
    }
  }
`;
