import React from 'react';
import { Typography } from '@/components/elements';
import { StyleElementTitle } from './style';
import { TagVariants } from '@/components/elements/Typography';
import classNames from 'classnames';

type TitleShadowProps = {
  tagPrologue?: TagVariants;
  tagTitle?: TagVariants;
  tagSubtitle?: TagVariants;
  colorPrologue?: string;
  colorTitle?: string;
  colorSubtitle?: string;
  classNamePrologue?: string;
  classNameTitle?: string;
  classNameSubtitle?: string;
  prologue?: string | React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  style?: React.CSSProperties;
  align?: 'left' | 'center' | 'right';
  subTitlePosition?: 'top' | 'bottom';
  rootClassName?: string;
};

const TitleShadow = (props: TitleShadowProps) => {
  const {
    tagPrologue = 'span',
    tagTitle = 'h2',
    tagSubtitle = 'span',
    colorPrologue = '#0081F9',
    colorTitle = '#FFFFFF',
    colorSubtitle = '#FFFFFF',
    classNamePrologue = '',
    classNameTitle = '',
    classNameSubtitle = '',
    prologue = '',
    title = '',
    subtitle = '',
    style,
    align = 'left',
    subTitlePosition = 'bottom',
    rootClassName = '',
  } = props;

  const wrapperStyle = classNames(
    {
      'align-prologue-center': subtitle && subTitlePosition === 'top',
    },
    rootClassName,
  );
  const alignPrologueStyle = classNames({
    'prologue-center text-center': align === 'center',
    'right-0': align === 'right',
  });
  const alignStyle = classNames({
    'text-center': align === 'center',
    'text-right': align === 'right',
  });
  return (
    <StyleElementTitle className={wrapperStyle} style={{ ...style }}>
      <div className="title-inner">
        {prologue && (
          <Typography
            tag={tagPrologue}
            color={colorPrologue}
            className={classNames(
              'prologue block',
              classNamePrologue,
              alignPrologueStyle,
            )}
          >
            {prologue}
          </Typography>
        )}
        {subtitle && subTitlePosition === 'top' ? (
          <Typography
            tag={tagSubtitle}
            color={colorSubtitle}
            className={classNames('subtitle-top block', classNameSubtitle, alignStyle)}
          >
            {subtitle}
          </Typography>
        ) : (
          ''
        )}
        {title && (
          <Typography
            tag={tagTitle}
            color={colorTitle}
            className={classNames('title', classNameTitle, alignStyle)}
          >
            {title}
          </Typography>
        )}
        {subtitle && subTitlePosition === 'bottom' ? (
          <Typography
            tag={tagSubtitle}
            color={colorSubtitle}
            className={classNames('subtitle block', classNameSubtitle, alignStyle)}
          >
            {subtitle}
          </Typography>
        ) : (
          ''
        )}
      </div>
    </StyleElementTitle>
  );
};

export default TitleShadow;
