import React from 'react';
import { ServiceItem } from '@/components/modules';

const ServiceGridWidget = () => {
  return (
    <div className="mx-auto grid max-w-screen-3xl grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
      <ServiceItem
        prologue="01"
        title="พัฒนาเว็บไซต์"
        serviceImg="/images/service/service-01.webp"
        serviceBg="#06B7B7"
        serviceUrl="website-development"
      />
      <ServiceItem
        prologue="02"
        title="พัฒนาแอปพลิเคชั่นมือถือ"
        serviceImg="/images/service/service-02.webp"
        serviceBg="#1990FF"
        serviceUrl="mobile-application-development"
      />
      <ServiceItem
        prologue="03"
        title="ออกแบบเว็บไซต์"
        serviceImg="/images/service/service-03.webp"
        serviceBg="#FFCA64"
        serviceUrl="website-design"
      />
      <ServiceItem
        prologue="04"
        title="เว็บไซต์สำหรับธุรกิจ SME"
        serviceImg="/images/service/service-04.webp"
        serviceBg="#FFAC79"
        serviceUrl="sme-website-development"
      />
      <ServiceItem
        prologue="05"
        title="ออกแบบและพัฒนา Line OA"
        serviceImg="/images/service/service-05.webp"
        serviceBg="#00BE00"
        serviceUrl="line-oa-development"
      />
      <ServiceItem
        prologue="06"
        title="พัฒนาซอฟต์แวร์ตามกำหนด"
        serviceImg="/images/service/service-06.webp"
        serviceBg="#FF7D7D"
        serviceUrl="custom-software-development"
      />
      <ServiceItem
        prologue="07"
        title="เพิ่มความปลอดภัย"
        serviceImg="/images/service/service-07.webp"
        serviceBg="#B577E5"
        serviceUrl="it-security"
      />
      <ServiceItem
        prologue="08"
        title="ดูแลบำรุงรักษาเว็บไซต์"
        serviceImg="/images/service/service-08.webp"
        serviceBg="#A4A4A4"
        serviceUrl="maintenance"
      />
    </div>
  );
};

export default ServiceGridWidget;
