import styled from 'styled-components';

export const StyleListBullet = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-bottom: 1rem;
    &:before {
      position: relative;
      display: inline-block;
      top: 2px;
      left: -3px;
      content: '';
      width: 20px;
      height: 20px;
      margin-right: 9px;
      background: #ffffff;
      border: 5px solid #1990ff;
      border-radius: 50%;
    }
  }
`;
