import React from 'react';
import { Field } from 'rc-field-form';
import type { Meta, FormInstance, Rule } from 'rc-field-form/lib/interface';
import type { InternalNamePath, NamePath, EventArgs } from './interface';

type ErrorProps = {
  children: string[];
};

interface ChildProps {
  [name: string]: any;
}

export type ShouldUpdate<Values = any> =
  | boolean
  | ((prevValues: Values, nextValues: Values, info: { source?: string }) => boolean);

type LabelProps<Values = any> = {
  name?: string | InternalNamePath;
  label?: string;
  labelHidden?: boolean;
  isCheckbox?: boolean;
  required?: boolean;
  className?: string;
  extra?: React.ReactNode;
  children?:
    | React.ReactElement
    | ((control: ChildProps, meta: Meta, form: FormInstance<Values>) => React.ReactNode);
  hasFeedback?: boolean;
  rules?: Rule[];
  dependencies?: NamePath[];
  getValueFromEvent?: (...args: EventArgs) => any;
};

const ErrorMessage = ({ children }: ErrorProps) => (
  <>
    {children.map((error) => (
      <span className="label-text-alt text-error" key={error}>
        {error}
      </span>
    ))}
  </>
);

function renderChildNode(
  children: LabelProps['children'],
  control: ChildProps,
  meta: Meta,
  form: FormInstance<string | InternalNamePath>,
  restProps: LabelProps,
): React.ReactNode {
  if (typeof children === 'function') {
    return children(control, meta, form);
  }
  return React.cloneElement(children as React.ReactElement, {
    meta,
    'data-name': restProps.name,
    ...control,
  });
}

const Label = (props: LabelProps) => {
  const {
    name,
    label,
    labelHidden = false,
    isCheckbox,
    children,
    required = false,
    className = '',
    extra,
    hasFeedback,
    ...restProps
  } = props;

  return (
    <Field name={name} {...restProps}>
      {(
        control: ChildProps,
        meta: Meta,
        form: FormInstance<string | InternalNamePath>,
      ): React.ReactNode => {
        const childNode = renderChildNode(children, control, meta, form, restProps);
        return (
          <div
            className={`form-control w-full ${
              isCheckbox
                ? `form-check flex w-full flex-row items-center space-x-2 ${className}`
                : className
            }`}
          >
            {label && !isCheckbox && (
              <label
                className={`label ${labelHidden ? 'sr-only' : ''}`}
                htmlFor={Array.isArray(name) ? name.join('-') : (name ?? '')}
              >
                <span className={`label-text ${label ? '' : 'sr-only'}`}>
                  {label ?? name}
                </span>
                {required && <span className="label-text-alt">*ต้องระบุ</span>}
              </label>
            )}

            {isCheckbox && label && (
              <>
                {childNode}
                <label
                  className={`label ${labelHidden ? 'sr-only' : ''}`}
                  htmlFor={Array.isArray(name) ? name.join('-') : (name ?? '')}
                >
                  <span className={`label-text ${label ? '' : 'sr-only'}`}>
                    {label ?? name}
                  </span>
                  {required && <span className="label-text-alt">*ต้องระบุ</span>}
                </label>
              </>
            )}

            {label ? childNode : null}

            {meta.errors.length > 0 && (
              <label className="label flex flex-col items-start gap-1">
                <ErrorMessage>{meta.errors}</ErrorMessage>
              </label>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default Label;
