/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable security/detect-unsafe-regex */
import React, { useEffect } from 'react';
import Link from 'next/link';
import Input from '@/components/elements/Input';
import TextArea from '@/components/elements/TextArea';
import Label from '@/components/elements/Label';
import Checkbox from '@/components/elements/Checkbox';
import Button from '@/components/elements/Button';
import Form, { useForm } from 'rc-field-form';
import ServiceSocial from '@/components/modules/ServiceSocial';
import Swal from 'sweetalert2';
import { regexNameWithSpecial, phoneRegexThai } from '@/enum/regexp';

type ServiceGetInTouchProps = {
  content?: string;
  secondContent?: React.ReactNode;
};

const ServiceGetInTouch = ({ content, secondContent }: ServiceGetInTouchProps) => {
  const [form] = useForm();

  useEffect(() => {
    const animateButton = (e: { preventDefault: () => void; currentTarget: any }) => {
      e.preventDefault();
      const target = e.currentTarget;

      const handleTransitionEnd = () => {
        // Remove the event listener to avoid multiple triggers
        target.removeEventListener('animationend', handleTransitionEnd);

        // Your custom onClick logic goes here
        console.log('Button clicked!');
        onFinish();
      };

      // Add a class to start the bubbly animation
      target.classList.add('animate');

      // Listen for the end of the animation
      target.addEventListener('animationend', handleTransitionEnd);
    };

    const buttons = document.querySelectorAll('.btn-bubbly');
    buttons.forEach((button) => {
      button.addEventListener('click', animateButton);
    });

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', animateButton);
      });
    };
  }, []);

  const swalSuccess = () => {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Your message has been sent.',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const swalError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      timer: 1500,
    });
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      await fetch(`https://formspree.io/f/${process.env.FORMSPREE_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: process.env.FORMSPREE_KEY,
          _from_name: values._from_name,
          name: values.name,
          phone: values.phone,
          email: values.email,
          subject: values.subject,
          message: values.message,
        }),
      })
        .then((res) => {
          res.json();
          swalSuccess();
          form.resetFields();
        })
        .catch(() => {
          swalError();
          form.resetFields();
        });
    });
  };
  return (
    <div className="container mx-auto space-y-8 px-4">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
        <div className="flex w-full flex-col justify-between">
          <div className="">
            <p>ยินดีให้คำปรึกษาและประเมินงบประมาณในการพัฒนา</p>
            <h2 className="my-4 text-5xl uppercase">Contact us</h2>
            <p>{content}</p>
            {secondContent}
          </div>
          <ServiceSocial />
        </div>
        <div className="pt-16 lg:px-8 lg:pt-0">
          <Form className="space-y-4" form={form} onFinish={onFinish}>
            <input type="hidden" name="_from_name" value="Twin Synergy" />
            <input type="hidden" name="_name" value="Twin Synergy" />
            <input type="hidden" name="subject" value="Contact us" />
            <Label
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please input your name!' },
                {
                  pattern: regexNameWithSpecial,
                  message: 'Wrong format!',
                },
              ]}
            >
              <Input type="text" name="name" className="input-primary w-full" />
            </Label>
            <Label
              name="phone"
              label=" Mobile phone"
              rules={[
                { required: true, message: 'Please input your phone!' },
                {
                  pattern: phoneRegexThai,
                  message: 'Format wrong!',
                },
              ]}
            >
              <Input
                type="text"
                name="phone"
                placeholder="0886559393"
                className="input-primary w-full"
              />
            </Label>
            <Label
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'The input is not valid E-mail!' },
              ]}
            >
              <Input type="email" name="email" className="input-primary w-full" />
            </Label>
            <Label
              name="message"
              label="Message"
              rules={[{ required: true, message: 'Please input your message!' }]}
            >
              {/* <Input type='text' name='message' className='input-primary w-full' /> */}
              <TextArea name="message" className="input-primary w-full" />
            </Label>
            <Label
              labelHidden
              label="privacy"
              name="privacy"
              rules={[
                { required: true, message: 'Please accept the terms and conditions!' },
              ]}
            >
              <div className="inline-block">
                <Checkbox
                  wrapperClassName="w-full"
                  name="privacy"
                  label={
                    <span>
                      I have read and agree to the website
                      <Link className="ml-1 inline-block text-primary" href="/privacy">
                        terms and conditions
                      </Link>
                    </span>
                  }
                />
              </div>
            </Label>
            <div className="pt-10">
              <Button className="btn-primary my-4 px-8" htmlType="submit">
                Get in touch
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ServiceGetInTouch;
