/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import TitleShadow from '../TitleShadow';
import { getNewPosts } from '@/service/blog';
import { NewPostItem } from '@/types';
import CardFeaturedArticles from '../CardFeaturedArticles';

gsap.registerPlugin(ScrollTrigger);

const FeaturedNewBlog = () => {
  const [newPosts, setNewPosts] = useState<NewPostItem[]>([]);
  const focusContainerRef = useRef<HTMLDivElement>(null);
  useIsomorphicLayoutEffect(() => {
    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: focusContainerRef.current,
        start: '-50px center',
        end: 'top center',
        // markers: true,
        // toggleActions: "restart pause reverse pause",
        // scrub: 1, // scrub is use set the amount of time to catch up, it kinda looks the scroll animation with ur scroller
      },
    });
    titleTl
      .addLabel('start')
      .from(focusContainerRef.current, { opacity: 0, translateX: '-100px' })
      .addLabel('entering')
      .to(focusContainerRef.current, { opacity: 1, translateX: '0px', duration: 0.5 })
      .addLabel('end');
    gsap.set('.customer-item', { opacity: 0, y: 20 });
    ScrollTrigger.batch('.customer-item', {
      start: '-160px center',
      end: 'top center',
      // markers: true,
      onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15 }),
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newPosts = await getNewPosts();
        setNewPosts(newPosts?.nodes);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="section-customer py-24">
      <div
        className="container mx-auto max-w-screen-3xl px-6 pt-28"
        ref={focusContainerRef}
      >
        <div className="mb-16">
          <TitleShadow
            prologue="บทความ"
            colorPrologue="#EFEFEF"
            title="บทความล่าสุด"
            colorTitle="#000000"
            subtitle="Articles"
            colorSubtitle="#000000"
            align="center"
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:text-left">
          {newPosts.map((post) => {
            const { title, slug, featuredImage, date, categories } = post;
            const categoryNodes = Array.isArray(categories?.nodes)
              ? categories?.nodes
              : [];
            return (
              <CardFeaturedArticles
                key={slug}
                title={title}
                slug={slug}
                featuredImage={featuredImage?.node?.mediaItemUrl}
                date={date}
                categories={categoryNodes}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedNewBlog;
