import styled from 'styled-components';

export const StyleCheckbox = styled.div`
  display: block;
  min-height: 1.5rem;
  padding-left: 2rem;
  margin-bottom: 0.125rem;
  input {
    float: left;
    margin-left: -2rem;
    &:focus {
      outline-offset: 0px;
    }
  }
  &.disabled {
    label {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;
