import React from 'react';
import { StyleSectionHeaderService } from './style';
import classNames from 'classnames';
import { Button, ImgResponsive } from '@/components/elements';
import { TitleShadow } from '@/components/modules';

type SectionHeaderServiceProps = {
  title?: string | React.ReactNode;
  bgImage?: string;
  bgImageMd?: string;
  bgImageSm?: string;
  bgColors?: string;
  style?: React.CSSProperties;
  className?: string;
  bgImageWidth?: number;
  bgImageHeight?: number;
  description?: string;
  btnText?: string;
  onClick?: () => void;
  prologue?: string | React.ReactNode;
  titleShadow?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  colorPrologue?: string;
  colorTitleShadow?: string;
  colorSubtitle?: string;
  align?: 'left' | 'center' | 'right';
  content?: string | React.ReactNode;
  subTitlePosition?: 'top' | 'bottom';
};

const SectionHeaderService = (props: SectionHeaderServiceProps) => {
  const {
    title = 'Article',
    description = `"Empowering your business with the Excellence website"`,
    btnText,
    bgImage = 'https://twinsynergy.co.th/wp-content/uploads/2022/03/twin-cover-2022-blue.jpg',
    bgImageMd,
    bgImageSm,
    bgColors = '#FFFFFF',
    style,
    className = '',
    bgImageWidth,
    bgImageHeight,
    titleShadow,
    colorTitleShadow,
    prologue,
    colorPrologue,
    align,
    content,
    onClick,
    subtitle,
    colorSubtitle,
    subTitlePosition = 'bottom',
  } = props;

  const mergeStyle = classNames('head-content', className);

  return (
    <StyleSectionHeaderService
      className={mergeStyle}
      style={{ backgroundColor: bgColors, ...style }}
    >
      <div className="bg-service-top min-h-[100vh - 120px] container mx-auto grid max-w-screen-3xl grid-cols-1 items-center px-4 lg:grid-cols-2">
        <ImgResponsive
          src={bgImage}
          alt="Twin Synergy"
          sourceImgMd={bgImageMd}
          sourceImgSm={bgImageSm}
          width={bgImageWidth}
          height={bgImageHeight}
        />
        <div className="space-y-8 px-4">
          <h1 className="head-content__title">{title}</h1>
          <p>{description}</p>
          {btnText && (
            <Button htmlType="button" className="btn-neutral" onClick={onClick}>
              {btnText}
            </Button>
          )}
        </div>
      </div>
      <div className="relative overflow-hidden">
        <div className="container mx-auto space-y-8 px-4 pb-24 pt-20 md:pb-36 md:pt-44">
          <TitleShadow
            prologue={prologue}
            colorPrologue={colorPrologue}
            title={titleShadow}
            colorTitle={colorTitleShadow}
            align={align}
            subtitle={subtitle}
            colorSubtitle={colorSubtitle}
            subTitlePosition={subTitlePosition}
          />
          {content}
        </div>
        <div className="absolute -bottom-2 -left-20 grid w-full lg:top-28 4xl:-left-20 4xl:bottom-0 4xl:top-40 4xl:max-w-7xl">
          <ImgResponsive
            src="/images/service/bg-service-brand.webp"
            alt="Twin Synergy"
            sourceImgMd="/images/service/bg-service-brand.webp"
            sourceImgSm="/images/service/bg-service-brand.webp"
            width={1200}
            height={1200}
            className="w-full scale-125 transform md:w-1/2 lg:scale-150 xl:scale-125 4xl:scale-150"
          />
        </div>
      </div>
    </StyleSectionHeaderService>
  );
};

export default SectionHeaderService;
