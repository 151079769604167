import { fetchAPI } from './base';

export async function getAllPostsForHome(first: number, after: any, search: string) {
  const data = await fetchAPI(
    `query AllPosts($first: Int!, $after: String, $search: String) {
      posts(first: $first, after: $after, where: {orderby: {field: DATE, order: DESC}, categoryNotIn: [645, 646, 647, 648], search: $search}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            databaseId
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
              }
            }
            author {
              node {
                name
                firstName
                lastName
                description
                email
                avatar {
                  url
                  height
                  size
                  width
                  default
                }
                mediaItems {
                  nodes {
                    sourceUrl
                    srcSet
                  }
                }
              }
            }
            tags {
              edges {
                node {
                  name
                  slug
                  uri
                }
              }
            }
            categories {
              edges {
                node {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        first,
        after,
        search,
      },
    },
  );

  return data?.posts;
}

export async function getPostAndMorePosts(first: number, after: any, search: string) {
  const data = await fetchAPI(
    `query getPosts($first: Int!, $after: String, $search: String) {
        posts(first: $first, after: $after, where: { categoryNotIn: [645, 646, 647, 648], orderby: {field: DATE, order: DESC}, search: $search } ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              databaseId
              title
              excerpt
              slug
              date
              featuredImage {
                node {
                  sourceUrl
                  srcSet
                  sizes
                }
              }
              author {
                node {
                  name
                  firstName
                  lastName
                  description
                  email
                  avatar {
                    url
                    height
                    size
                    width
                    default
                  }
                  mediaItems {
                    nodes {
                      sourceUrl
                      srcSet
                    }
                  }
                }
              }
              tags {
                edges {
                  node {
                    name
                    slug
                    uri
                  }
                }
              }
              categories {
                edges {
                  node {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        first,
        after,
        search,
      },
    },
  );
  return data?.posts;
}
// `query GetPost($id: ID = "") {
//     post(id: $id, idType: SLUG) {
//       content
//       featuredImage {
//         node {
//           sourceUrl
//         }
//       }
//       slug
//       title
//     }
//   }`,
export async function getPostBySlug(slug: string) {
  const data = await fetchAPI(
    `query PostBySlug($slug: ID!) {
      post(id: $slug, idType: SLUG ) {
        author {
          node {
            slug
            name
            firstName
            lastName
            description
            email
            avatar {
              url
              height
              size
              width
              default
            }
            mediaItems {
              nodes {
                sourceUrl
                srcSet
              }
            }
          }
        }
        id
        title
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
          }
        }
        content(format: RENDERED)
        slug
        date
        tags {
          edges {
            node {
              name
              slug
            }
          }
        }
        categories {
          edges {
            node {
              name
              slug
            }
          }
          nodes {
            name
            slug
          }
        }
      }
    }`,
    {
      variables: {
        slug,
      },
    },
  );

  return data?.post;
}

export async function getPostNav(slug: string) {
  // console.log('slug', slug)
  const data = await fetchAPI(
    `query getPosts($slug: String!) {
        posts(
          after: $slug
          before: $slug
          last: 4
          where: { categoryNotIn: [645, 646, 647, 648], orderby: {field: DATE, order: DESC} } ) {
        ) {
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
            startCursor
          }
          nodes {
            title
            uri
            slug
          }
        }
    `,
    {
      variables: {
        slug,
      },
    },
  );
  return data?.posts;
}

export async function getAllPostsWithSlug() {
  const data = await fetchAPI(`
    {
      posts(first: 10000, where: { categoryNotIn: [645, 646, 647, 648], orderby: {field: DATE, order: DESC} } ) {
        nodes {
          slug
          title
        }
      }
    }
  `);
  return data?.posts;
}

export async function getCategories() {
  const data = await fetchAPI(`
    {
      categories {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);
  return data?.categories;
}

export async function getCategoriesName(cate: string) {
  const data = await fetchAPI(
    `query categories($cate: String) {
      categories(where: {search: $cate}) {
        edges {
          node {
            name
            slug
            description
          }
        }
      }
    }`,
    {
      variables: {
        cate,
      },
    },
  );
  return data?.categories;
}

export async function getRelatedPost(first: number, after: any, cate: string) {
  const data = await fetchAPI(
    `query RelatedPosts($first: Int!, $after: String, $cate: String) {
      posts(
        first: $first
        after: $after
        where: { categoryName: $cate, categoryNotIn: [645, 646, 647, 648], orderby: {field: DATE, order: DESC} } ) {
          pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            databaseId
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
              }
            }
            author {
              node {
                name
                firstName
                lastName
                description
                email
                avatar {
                  url
                  height
                  size
                  width
                  default
                }
                mediaItems {
                  nodes {
                    sourceUrl
                    srcSet
                  }
                }
              }
            }
            tags {
              edges {
                node {
                  name
                  slug
                  uri
                }
              }
            }
            categories {
              edges {
                node {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }`,
    {
      variables: {
        first,
        after,
        cate,
      },
    },
  );
  return data?.posts;
}

export async function AllCategory() {
  const data = await fetchAPI(`
    query AllCategory {
      terms(where: {taxonomies: CATEGORY, exclude: [1, 647, 648, 646]}) {
        edges {
          node {
            name
            slug
            id
            count
          }
        }
      }
    }
  `);
  return data?.terms;
}

export async function AllTags() {
  const data = await fetchAPI(`
    query AllTags {
      terms(where: {taxonomies: TAG}) {
        edges {
          node {
            name
            slug
            id
            count
          }
        }
      }
    }
  `);
  return data?.terms;
}

export async function getPostsByCategories(
  first: number,
  after: any,
  cate: string,
  search: string,
) {
  const data = await fetchAPI(
    `
    query getPostsByCategories($first: Int!, $after: String, $cate: String, $search: String) {
      posts(first: $first, after: $after, where: {categoryName: $cate, categoryNotIn: [645, 646, 647, 648], search: $search}) {
        nodes {
          title
          categories {
            nodes {
              name
              description
            }
          }
          slug
          excerpt
          databaseId
          slug
          date
          featuredImage {
            node {
              sourceUrl
              srcSet
              sizes
            }
          }
          author {
            node {
              name
              firstName
              lastName
              description
              email
              avatar {
                url
                height
                size
                width
                default
              }
              mediaItems {
                nodes {
                  sourceUrl
                  srcSet
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
    {
      variables: {
        first,
        after,
        cate,
        search,
      },
    },
  );
  return data?.posts;
}

export async function getPostAndMorePostsCategories(
  first: number,
  after: any,
  cate: string,
) {
  const data = await fetchAPI(
    ` query getPostsByCategories($first: Int!, $after: String, $cate: String) {
        posts(first: $first, after: $after, where: {categoryName: $cate, categoryNotIn: [645, 646, 647, 648]}) {
          nodes {
            title
            categories {
              nodes {
                name
              }
            }
            slug
            excerpt
            databaseId
            slug
            date
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
              }
            }
            author {
              node {
                name
                firstName
                lastName
                description
                email
                avatar {
                  url
                  height
                  size
                  width
                  default
                }
                mediaItems {
                  nodes {
                    sourceUrl
                    srcSet
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
    {
      variables: {
        first,
        after,
        cate,
      },
    },
  );
  return data?.posts;
}

export async function getPostsByTags(first: number, after: any, tag: string) {
  const data = await fetchAPI(
    `
    query getPostsByTags($first: Int!, $after: String, $tag: String) {
      posts(where: {tag: $tag}, first: $first, after: $after) {
        nodes {
          title
          categories {
            nodes {
              name
            }
          }
          slug
          excerpt
          databaseId
          slug
          date
          featuredImage {
            node {
              sourceUrl
              srcSet
              sizes
            }
          }
          author {
            node {
              name
              firstName
              lastName
              description
              email
              avatar {
                url
                height
                size
                width
                default
              }
              mediaItems {
                nodes {
                  sourceUrl
                  srcSet
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
    {
      variables: {
        first,
        after,
        tag,
      },
    },
  );
  return data?.posts;
}

export async function getPostAndMorePostsTags(limit: number, after: any, cate: string) {
  const data = await fetchAPI(
    `query getPostsByTags($limit: Int!, $after: String, $tag: String) {
        posts(where: {tag: $tag}, first: $limit, after: $after) {
          nodes {
            title
            categories {
              nodes {
                name
              }
            }
            slug
            excerpt
            databaseId
            slug
            date
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
              }
            }
            author {
              node {
                name
                firstName
                lastName
                description
                email
                avatar {
                  url
                  height
                  size
                  width
                  default
                }
                mediaItems {
                  nodes {
                    sourceUrl
                    srcSet
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
    {
      variables: {
        limit,
        after,
        cate,
      },
    },
  );
  return data?.posts;
}

export async function getNewPosts() {
  const data = await fetchAPI(
    `query NewPost {
      posts(
        where: {orderby: {field: DATE, order: DESC}, categoryNotIn: [645, 646, 647, 648]}
        first: 3
      ) {
        nodes {
          slug
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          date
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  `,
  );

  return data?.posts;
}
