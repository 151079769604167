import styled from 'styled-components';

export const StyleServiceSocial = styled.div<{ portBg?: string }>`
  .contact-items {
    border: 0;
    border-radius: 0;
    box-shadow:
      2px 0 0 0 #e5e7eb,
      0 2px 0 0 #e5e7eb,
      2px 2px 0 0 #e5e7eb,
      2px 0 0 0 #e5e7eb inset,
      0 2px 0 0 #e5e7eb inset;
    transition: all 0.2s;
    height: 100%;
    p {
      @media only screen and (min-width: 992px) {
        font-size: 14px;
      }
      @media only screen and (min-width: 1280px) {
        font-size: 16px;
      }
      @media only screen and (min-width: 1440px) {
        font-size: 16px;
      }
    }
  }
`;
