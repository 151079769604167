import styled from 'styled-components';

export const StyleSectionHeader = styled.div`
  padding-top: 60px;
  padding-bottom: 35px;
  @media only screen and (min-width: 768px) {
    padding-top: 70px;
  }
  .header-content-center {
    width: 100%;
    position: relative;
  }
  .header-background-style {
    picture {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .el-style-content {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    @media only screen and (min-width: 640px) {
      top: 38%;
    }
  }
  .head-content__title {
    h1 {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 10px;
    }
  }
  &.homepage {
    .el-style-content {
      top: 45%;
      @media only screen and (min-width: 640px) {
        top: 43%;
      }
    }
  }
`;
