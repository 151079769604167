import React, { useRef } from 'react';
import { StyleSectionHeader } from './style';
import classNames from 'classnames';
import { ImgResponsive } from '@/components/elements';
import FadeIn from '@/components/animation/FadeIn';
import { gsap } from 'gsap';
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect';

type SectionHeaderProps = {
  title?: string | React.ReactNode;
  bgImage?: string;
  bgImageMd?: string;
  bgImageSm?: string;
  bgColors?: string;
  style?: React.CSSProperties;
  className?: string;
  bgImageWidth?: number;
  bgImageHeight?: number;
  delay?: number;
  x?: number;
  y?: number;
  duration?: number;
};

const SectionHeader = (props: SectionHeaderProps) => {
  const {
    title = 'Article',
    bgImage = 'https://twinsynergy.co.th/wp-content/uploads/2022/03/twin-cover-2022-blue.jpg',
    bgImageMd,
    bgImageSm,
    bgColors = '#FFFFFF',
    style,
    className = '',
    bgImageWidth,
    bgImageHeight,
    delay = 0.5,
    x = -50,
    y = 0,
    duration,
  } = props;
  const mergeStyle = classNames('head-content', className);
  const greensockRef = useRef<HTMLImageElement>(null);
  const nextJsRef = useRef<HTMLImageElement>(null);
  useIsomorphicLayoutEffect(() => {
    // intro animations
    // ref is an object with a current property that holds the DOM element
    const tl = gsap.timeline();
    tl.from(nextJsRef.current, {
      duration: 0.5,
      translateY: -5,
      opacity: 1,
    });
    tl.to(greensockRef.current, {
      duration: 1.5,
      translateY: -5,
      opacity: 1,
    });
    tl.to(nextJsRef.current, {
      duration: 1.2,
      translateY: -5,
      opacity: 1,
    });
    // tl.to(
    //   greensockRef.current,
    //   {
    //     clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
    //   },
    //   '-=.1',
    // )
  }, []);
  return (
    <StyleSectionHeader
      className={mergeStyle}
      style={{ backgroundColor: bgColors, ...style }}
    >
      <div className="header-content-center">
        <div className="header-background-style el-style" ref={greensockRef}>
          <ImgResponsive
            src={bgImage}
            alt="Twin Synergy"
            sourceImgMd={bgImageMd}
            sourceImgSm={bgImageSm}
            width={bgImageWidth}
            height={bgImageHeight}
          />
        </div>
        <div className="el-style-content">
          <div className="head-content__title px-4">
            <FadeIn x={x} y={y} delay={delay} duration={duration}>
              <h1 className="text-center text-5xl lg:text-7xl" ref={nextJsRef}>
                {title}
              </h1>
            </FadeIn>
          </div>
        </div>
      </div>
    </StyleSectionHeader>
  );
};

export default SectionHeader;
